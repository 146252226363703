<template>
  <v-container class="px-3 py-0" fluid>
    <v-row>
      <v-col cols="12" class="mt-sm-6">
        <v-card class="mx-auto" elevation="0">
          <base-material-card
            color="primary"
            width="2600"
            flat
            :icon="routeInfo.icon"
            :title="routeInfo.title"
          >
            <router-view></router-view>
          </base-material-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Admin",
  computed: {
    routeInfo() {
      switch (this.$route.name) {
        case "Usuários N":
          return { icon: "mdi-account-circle", title: "Gerência Usuários" };
        case "Cidades Basis":
          return { icon: "mdi-linux", title: "Gerência Cidades Basis" };
        case "Administração":
          return { icon: "settings", title: "Gerência ProMarket" };
        case "Virada de safra":
          return { icon: "mdi-refresh", title: "Virada de safra" };
        case "Safra":
          return { icon: "mdi-tractor", title: "Safra" };
        case "Cultura":
          return { icon: "mdi-corn", title: "Cultura" };

        default:
          return { icon: "mdi-help", title: "Desconhecido" };
      }
    },
  },
};
</script>